
import { defineComponent } from 'vue'
import HeaderProfileHero from '@/components/layout/header/headerProfile/HeaderProfileHero.vue'
import HeaderProfileMenu from '@/components/layout/header/headerProfile/HeaderProfileMenu.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import HeaderProfileButton from '@/components/layout/header/headerProfile/HeaderProfileButton.vue'

export default defineComponent({
  components: {
    HeaderProfileButton,
    HeaderProfileHero,
    HeaderProfileMenu,
    TmDropdown,
  },
})
