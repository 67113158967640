
import { defineComponent } from 'vue'
import TmHeader from '@/components/layout/header/Header.vue'
import HeaderProfile from '@/components/layout/header/HeaderProfile.vue'
import HeaderProfileMobile from '@/components/layout/header/HeaderProfileMobile.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    HeaderProfile,
    HeaderProfileMobile,
    TmHeader,
  },
  setup() {
    const { isMdMax } = useBreakpoints()

    return {
      isMdMax,
    }
  },
})
