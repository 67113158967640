
import { defineComponent, ref } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: {
    TmDropdownItem,
  },
  setup() {
    const menu = ref([
      {
        icon: 'tmi-person-circle-outline',
        label: 'My account',
        to: { name: 'base.admin.myAccount' },
      },
      {
        icon: 'notifications_none',
        label: 'Notifications',
        to: { name: 'base.admin.notifications' },
      },
      {
        icon: 'tmi-person-group-outline',
        label: 'Users',
        to: { name: 'base.admin.teamMembers' },
      },
      {
        icon: 'credit_card',
        label: 'Billing',
        to: { name: 'base.admin.overview' },
      },
      {
        icon: 'tmi-print-outline',
        label: 'Invoices',
        to: { name: 'base.admin.invoices' },
        borderBottom: true,
      },
      {
        icon: 'tmi-awesome-outline',
        label: 'What’s new',
        href: 'https://support.textmagic.com/changelog/',
        outside: true,
      },
      {
        icon: 'tmi-help-outline',
        label: 'Help & support',
        href: 'https://support.textmagic.com/',
        outside: true,
        borderBottom: true,
      },
      {
        icon: 'tmi-logout-exit',
        label: 'Log out',
        textColor: 'red',
        to: { name: 'auth.login' },
      },
    ])
    return {
      menu,
    }
  },
})
