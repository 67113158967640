
import { defineComponent } from 'vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
  },
  setup() {
    const { isMdMax } = useBreakpoints()
    const { openModal } = useModals()

    return {
      isMdMax,
      openModal,
    }
  },
})
