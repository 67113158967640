import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
      return (_openBlock(), _createBlock(_component_tm_dropdown_item, _mergeProps({
        key: item.label
      }, item, {
        "icon-size": "xLarge",
        "header-style": ""
      }), null, 16))
    }), 128))
  ]))
}