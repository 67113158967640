import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-670d9f1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "single-page-container" }
const _hoisted_2 = { class: "single-page-container__content" }
const _hoisted_3 = { class: "single-page-container__content-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_single_page = _resolveComponent("header-single-page")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!
  const _component_tm_content = _resolveComponent("tm-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_header_single_page),
        _createVNode(_component_tm_scrollbar, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_view)
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}