import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36b03908"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-profile__menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_profile_button = _resolveComponent("header-profile-button")!
  const _component_header_profile_hero = _resolveComponent("header-profile-hero")!
  const _component_header_profile_menu = _resolveComponent("header-profile-menu")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, {
    position: "bottom-left",
    "border-radius": ""
  }, {
    menu: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_header_profile_hero),
        _createVNode(_component_header_profile_menu)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_header_profile_button)
    ]),
    _: 1
  }))
}