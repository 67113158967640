
import { defineComponent } from 'vue'
import TmContent from '@/components/layout/TmContent.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import HeaderSinglePage from '@/components/layout/header/HeaderSinglePage.vue'

export default defineComponent({
  components: {
    HeaderSinglePage,
    TmScrollbar,
    TmContent,
  },
})
