import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5baa7d92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-profile-mobile" }
const _hoisted_2 = { class: "pa-6" }
const _hoisted_3 = { class: "mt-auto pa-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_header_profile_hero = _resolveComponent("header-profile-hero")!
  const _component_header_profile_menu = _resolveComponent("header-profile-menu")!
  const _component_header_profile_balance = _resolveComponent("header-profile-balance")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.getProfileState)
      ? (_openBlock(), _createBlock(_component_tm_avatar, {
          key: 0,
          name: _ctx.assignee.fullName,
          color: _ctx.assignee.avatarColor,
          "custom-size": "32px",
          class: "pointer",
          onClick: _ctx.updateProfileState
        }, null, 8, ["name", "color", "onClick"]))
      : (_openBlock(), _createBlock(_component_tm_button, {
          key: 1,
          flat: "",
          "icon-only": "",
          color: "transparent",
          onClick: _ctx.updateProfileState
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              name: "close",
              size: "xxxLarge",
              class: "distinct--text"
            })
          ]),
          _: 1
        }, 8, ["onClick"])),
    _createElementVNode("div", {
      class: _normalizeClass(["header-profile-mobile__menu", {
        'header-profile-mobile__menu--active': _ctx.getProfileState,
      }])
    }, [
      _createVNode(_component_tm_scrollbar, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_header_profile_hero),
            _createVNode(_component_header_profile_menu, { class: "mb-6" })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_header_profile_balance)
          ])
        ]),
        _: 1
      })
    ], 2)
  ]))
}