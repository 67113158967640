
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import HeaderProfileHero from '@/components/layout/header/headerProfile/HeaderProfileHero.vue'
import HeaderProfileBalance from '@/components/layout/header/headerProfile/HeaderProfileBalance.vue'
import HeaderProfileMenu from '@/components/layout/header/headerProfile/HeaderProfileMenu.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { useMobileNavigation } from '@/compositions/mobileNavigation'

export default defineComponent({
  components: {
    TmAvatar,
    TmScrollbar,
    TmButton,
    HeaderProfileBalance,
    HeaderProfileHero,
    HeaderProfileMenu,
  },
  setup() {
    const { getProfileState, updateProfileState } = useMobileNavigation()
    const assignee = {
      avatarColor: 'orange',
      fullName: 'John Doe',
    }

    return {
      assignee,
      getProfileState,
      updateProfileState,
    }
  },
})
